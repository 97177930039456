import React from 'react';
import NumberFormat from 'react-number-format';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Helpers } from '../../../../utils/helpers';
import { PagingControls } from '../../../../components/ui';
import { PaymentConfirmationModal } from '../PaymentConfirmationModal/PaymentConfirmationModal';
import useResearchSearch from './useResearchSearch';
import './ResearchSearch.css';
import {
  ResearchDateRangeType,
  ResearchSearchType,
  PaymentConfirmationView,
  TransactionStatus
} from '../../../../utils/constants/enums';

export const ResearchSearch = () => {
  const search = useResearchSearch();

  return (
    <>
      <PaymentConfirmationModal
        confirmationParams={search.modalConfirmationParams}
        userCanVoid={search.userCanVoid}
        userCanChargeback={search.userCanChargeback}
        closeCallback={search.closeModalConfirmation}
        viewChangeCallback={search.confirmationViewChange}
        reloadSearchCallback={search.reloadSearch}
      />
      <div className="titleBar">
        <h1 className="pageTitle">Research</h1>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="searchContainer border mt-0">
              <div className="g-3 heading">
                <div className="col-auto">
                  <h2 className="h6">Search by Confirmation Number</h2>
                </div>
              </div>
              <div className="g-3 w-100">
                <div className="form-group">
                  <label htmlFor="confirmationNumber">Confirmation Number (numbers only)</label>
                  <input
                    type="text"
                    id="confirmationNumber"
                    className="form-control"
                    placeholder=""
                    maxLength={15}
                    value={search.searchParameters.confirmationNumber}
                    onKeyDown={(e) => search.handleSearchKeyDown(e, ResearchSearchType.BASIC)}
                    onChange={(e) =>
                      search.updateSearchParams('confirmationNumber', e.target.value, {
                        numbersOnly: true
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="emailAddress">Email Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="emailAddress"
                    value={search.searchParameters.emailAddress}
                    onKeyDown={(e) => search.handleSearchKeyDown(e, ResearchSearchType.BASIC)}
                    onChange={(e) => search.updateSearchParams('emailAddress', e.target.value)}
                  />
                  {search.errors.emailAddress && (
                    <div className="alert-danger mt-1 p-2">
                      This is not a valid address. Please enter a valid email address.
                    </div>
                  )}
                </div>
                {search.errors.basicSearchMissing && (
                  <div className="alert-danger mt-1 p-2">
                    Confirmation number or email address required.
                  </div>
                )}
                <div className="mt-3 d-flex justify-content-between">
                  <button
                    className="searchSubmit btn btn-primary"
                    onClick={search.resetBasicSearch}>
                    Reset
                  </button>
                  <button className="searchSubmit btn btn-primary" onClick={search.runBasicSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>

            <p className="h6">OR</p>

            <div className="searchContainer border">
              <div className="g-3 heading">
                <div className="col-auto">
                  {search.defaultCustomer ? (
                    <h2 className="h6">Select one or more of the following:</h2>
                  ) : (
                    <h2 className="h6">Select a customer and one or more of the following:</h2>
                  )}
                </div>
              </div>
              <div className="g-3 w-100">
                <div className="form-group">
                  <label htmlFor="customer">
                    Customer {!search.defaultCustomer && <span className="req">*</span>}
                  </label>
                  {search.defaultCustomer && (
                    <div className="researchCustomerName">{search.defaultCustomer.name}</div>
                  )}
                  {!search.defaultCustomer && (
                    <>
                      <select
                        id="customer"
                        className="form-select"
                        aria-label="Customer selection"
                        value={search.searchParameters.customerId ?? ''}
                        onChange={(e) =>
                          search.updateSearchParams(
                            'customerId',
                            Helpers.tryParseInt(e.target.value, null)
                          )
                        }>
                        <option>- Select -</option>
                        {search.customers.map((customer) => {
                          return (
                            <option key={customer.id} value={customer.id}>
                              {customer.name}
                            </option>
                          );
                        })}
                      </select>
                      {search.errors.customerId && (
                        <div className="mt-1 alert-danger p-2">Customer selection required.</div>
                      )}
                    </>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="transaction">Transaction</label>
                  <select
                    id="transaction"
                    className="form-select"
                    aria-label="Transaction selection"
                    disabled={(search.transactions?.length ?? 0) === 0}
                    value={search.searchParameters.transactionId ?? ''}
                    onChange={(e) =>
                      search.updateSearchParams(
                        'transactionId',
                        Helpers.tryParseInt(e.target.value, null)
                      )
                    }>
                    <option>- Select -</option>
                    {search.transactions?.map((transaction) => {
                      return (
                        <option
                          key={transaction.id}
                          value={transaction.id}
                          className={
                            transaction.status === TransactionStatus.INACTIVE ? 'inactive' : ''
                          }>
                          {transaction.name}
                          {transaction.status === TransactionStatus.INACTIVE && ' [inactive]'}
                        </option>
                      );
                    })}
                  </select>
                  <div className="transaction-show-inactive">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => search.toggleInactiveTransactions()}
                          checked={search.showInactiveTransactions}
                        />
                        <em>Show Inactive</em>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row dateRangeWrap">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="date-range" className="text-nowrap">
                        Date Range
                      </label>
                      <select
                        id="date-range"
                        className="form-select"
                        aria-label="Date Range selection"
                        value={search.searchParameters.dateRangeType}
                        onChange={(e) =>
                          search.updateSearchParams(
                            'dateRangeType',
                            Helpers.tryParseInt(e.target.value, null)
                          )
                        }>
                        <option value={ResearchDateRangeType.TODAY}>Today</option>
                        <option value={ResearchDateRangeType.YESTERDAY}>Yesterday</option>
                        <option value={ResearchDateRangeType.PREVIOUS_3_DAYS}>
                          Previous 3 Days
                        </option>
                        <option value={ResearchDateRangeType.PREVIOUS_7_DAYS}>
                          Previous 7 Days
                        </option>
                        <option value={ResearchDateRangeType.PREVIOUS_MONTH}>Previous Month</option>
                        <option value={ResearchDateRangeType.PREVIOUS_QUARTER}>
                          Previous Quarter
                        </option>
                        <option value={ResearchDateRangeType.PREVIOUS_YEAR}>Previous Year</option>
                        <option value={ResearchDateRangeType.CUSTOM}>Custom</option>
                      </select>
                    </div>
                  </div>
                  {search.searchParameters.dateRangeType === ResearchDateRangeType.CUSTOM && (
                    <>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="fromDate">From:</label>
                          <input
                            type="date"
                            id="fromDate"
                            className="form-control"
                            value={search.searchParameters.fromDate ?? ''}
                            onChange={(e) => search.updateSearchParams('fromDate', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="toDate">To:</label>
                          <input
                            type="date"
                            id="toDate"
                            className="form-control"
                            value={search.searchParameters.toDate ?? ''}
                            onChange={(e) => search.updateSearchParams('toDate', e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {search.errors.customDateMissing && (
                  <div className="row">
                    <div className="col-12">
                      <div className="mt-1 alert-danger p-2">
                        Date fields are required for custom date range selection.
                      </div>
                    </div>
                  </div>
                )}

                {search.errors.customDateInvalid && (
                  <div className="row">
                    <div className="col-12">
                      <div className="mt-1 alert-danger p-2">
                        Custom date fields cannot include today&apos;s date in the date range.
                      </div>
                    </div>
                  </div>
                )}

                {search.lookupFieldLoading && (
                  <div>
                    <Spinner animation="border" size="sm" className="me-2" />
                    <em>Checking for lookup fields</em>
                  </div>
                )}

                {!search.lookupFieldLoading &&
                  search.searchParameters.transactionId &&
                  search.lookupFields.length > 0 && (
                    <div className="g-3 w-100">
                      {search.lookupFields.map((field) => {
                        return (
                          <div key={field.dataColumnName} className="form-group">
                            <label htmlFor={field.dataColumnName}>{field.fieldName}</label>
                            <input
                              type="text"
                              id={field.dataColumnName}
                              className="form-control"
                              maxLength={field.maxLength > 0 ? field.maxLength : null}
                              value={
                                search.searchParameters.lookupValues[field.dataColumnName] ?? ''
                              }
                              onChange={(e) =>
                                search.updateLookupParams(field.dataColumnName, e.target.value)
                              }
                              onKeyDown={(e) =>
                                search.handleSearchKeyDown(e, ResearchSearchType.ADVANCED)
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}

                <div className="row billWrap">
                  <div className="col-auto">
                    <div className="form-group">
                      <label htmlFor="payor-name">Payor Name:</label>
                      <input
                        type="text"
                        id="payor-name"
                        className="form-control"
                        maxLength={100}
                        value={search.searchParameters.payorName}
                        onKeyDown={(e) =>
                          search.handleSearchKeyDown(e, ResearchSearchType.ADVANCED)
                        }
                        onChange={(e) => search.updateSearchParams('payorName', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="form-group">
                      <label htmlFor="phone">Phone Number:</label>
                      <NumberFormat
                        id="phone"
                        className="form-control"
                        aria-describedby="phoneHelp"
                        format="(###) ###-####"
                        mask="_"
                        allowEmptyFormatting={true}
                        value={search.searchParameters.phoneNumber}
                        onKeyDown={(e) =>
                          search.handleSearchKeyDown(e, ResearchSearchType.ADVANCED)
                        }
                        onChange={(e) =>
                          search.updateSearchParams('phoneNumber', e.target.value, {
                            numbersOnly: true
                          })
                        }
                      />
                      <small id="phoneHelp" className="form-text text-muted">
                        Numeric, 10 digits, no special characters
                      </small>
                    </div>
                  </div>
                </div>
                {search.errors.advancedFieldMissing && (
                  <div className="mt-1 alert-danger p-2">
                    You must enter at least one search field in addition to the customer selection.
                  </div>
                )}

                <div className="mt-3 d-flex justify-content-between">
                  <button
                    className="searchSubmit btn btn-primary"
                    onClick={search.resetAdvancedSearch}>
                    Reset
                  </button>
                  <button
                    className="searchSubmit btn btn-primary"
                    onClick={search.runAdvancedSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-8">
            {search.apiError.trim() !== '' && (
              <div className="alert-danger mb-1 p-2">{search.apiError}</div>
            )}
            <div className="card">
              <div className="card-body">
                {search.loading && <Spinner animation="border" />}
                {!search.loading && (
                  <div className="table-responsive">
                    {!search.pagedResult && (
                      <>
                        <h2>Search for Payments</h2>
                        <div>Use the search forms to find payments.</div>
                      </>
                    )}
                    {search.pagedResult && search.pagedResult.results.length === 0 && (
                      <>
                        {search.pagedResult.resultsFiltered && (
                          <>
                            <h2>Payment Details Not Available</h2>
                            <div>
                              Due to your user permissions, you do not have access to this payment.
                            </div>
                          </>
                        )}
                        {!search.pagedResult.resultsFiltered && (
                          <>
                            <h2>No Results for Your Search</h2>
                            <div>Please update your search criteria and try again.</div>
                          </>
                        )}
                      </>
                    )}
                    {search.pagedResult?.results.length > 0 && (
                      <>
                        <div className="resultCount">
                          {search.pagedResult.resultCount} result
                          {search.pagedResult.resultCount === 1 ? '' : 's'} found.
                        </div>
                        <h2>Results</h2>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Confirmation No.</th>
                              <th scope="col">Customer</th>
                              <th scope="col">Transaction Name</th>
                              <th scope="col">Payor Name</th>
                              <th scope="col">Date/Time</th>
                              {search.canViewReceipt &&
                                (search.userCanVoid || search.userCanChargeback) && (
                                  <th scope="col"></th>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {search.pagedResult?.results.map((result) => {
                              return (
                                <tr
                                  key={result.cpiId}
                                  className={search.isRowViewed(result.cpiId) ? 'row-viewed' : ''}>
                                  <td>
                                    {!search.canViewReceipt && result.confirmationNumber}
                                    {search.canViewReceipt && (
                                      <a
                                        href="#!"
                                        onClick={(e) =>
                                          search.handleConfirmationLink(
                                            e,
                                            result.cpiId,
                                            result.source,
                                            result.confirmationNumber,
                                            result.canVoid,
                                            result.canChargeback,
                                            PaymentConfirmationView.DEFAULT
                                          )
                                        }>
                                        {result.confirmationNumber}
                                      </a>
                                    )}
                                  </td>
                                  <td>{result.customerName}</td>
                                  <td>{result.transactionName}</td>
                                  <td>{result.payorName}</td>
                                  <td>{Helpers.toShortDateTime(result.processed)}</td>
                                  {search.canViewReceipt &&
                                    (search.userCanVoid || search.userCanChargeback) && (
                                      <td>
                                        <div className="d-flex">
                                          {search.userCanVoid && (
                                            <button
                                              href="#!"
                                              className="btn btn-primary btn-sm icon-button"
                                              onClick={(e) =>
                                                search.handleConfirmationLink(
                                                  e,
                                                  result.cpiId,
                                                  result.source,
                                                  result.confirmationNumber,
                                                  result.canVoid,
                                                  result.canChargeback,
                                                  PaymentConfirmationView.VOID
                                                )
                                              }
                                              disabled={result.voided || !result.canVoid}
                                              title="Void">
                                              <FontAwesomeIcon size="sm" icon={faBan} />
                                            </button>
                                          )}
                                          {search.userCanChargeback && (
                                            <button
                                              href="#!"
                                              className="btn btn-primary btn-sm icon-button"
                                              onClick={(e) =>
                                                search.handleConfirmationLink(
                                                  e,
                                                  result.cpiId,
                                                  result.source,
                                                  result.confirmationNumber,
                                                  result.canVoid,
                                                  result.canChargeback,
                                                  PaymentConfirmationView.CHARGEBACK
                                                )
                                              }
                                              disabled={result.chargedBack || !result.canChargeback}
                                              title="Chargeback">
                                              <FontAwesomeIcon size="sm" icon={faDollarSign} />
                                            </button>
                                          )}
                                        </div>
                                      </td>
                                    )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div>
                          <PagingControls
                            page={search.pagedResult.currentPage}
                            pageCount={search.pagedResult.pageCount}
                            changePageCallback={search.changePage}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
